import React from "react";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

interface ParallaxProps {
	filter?: boolean;
	image?: string;
	xsmall?: boolean;
	small?: boolean;
	medium?: boolean;
	style?: string;
	className?: string;
	children?: React.ReactNode;
}

export default function Parallax({
									 className,
									 filter,
									 children,
									 style,
									 image,
									 xsmall,
									 small,
									 medium,
								 }: ParallaxProps) {
	const theme = useTheme();

	// Media queries for responsive behavior
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

	const altezzaImmagine = 68; // altezza immagine in unità viewport per default
	const [transform, setTransform] = React.useState("translate3d(0,0,0)");

	const resetTransform = () => {
		const windowScrollTop = window.pageYOffset / 3;
		setTransform("translate3d(0," + windowScrollTop + "px,0)");
	};

	React.useEffect(() => {
		if (!isSmallScreen) {
			window.addEventListener("scroll", resetTransform);
		}
		return () => {
			if (!isSmallScreen) {
				window.removeEventListener("scroll", resetTransform);
			}
		};
	}, [isSmallScreen]);

	// Dynamic height based on screen size
	const dynamicHeight = React.useMemo(() => {
		if (isSmallScreen) return "80vh"; // Altezza per schermi piccoli
		if (isMediumScreen) return "85vh"; // Altezza media per tablet
		return "88vh"; // Altezza completa per schermi grandi
	}, [isSmallScreen, isMediumScreen]);

	return (
		<Box
			sx={{
				height: dynamicHeight,
				overflow: isSmallScreen ? "auto" : "auto", // Scroll interno su schermi piccoli
				position: "relative",
				backgroundPosition: "center center",
				backgroundSize: "cover",
				backgroundImage: `url(${image})`,
				transform: transform,
				margin: 0,
				padding: 0,
				border: 0,
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					justifyContent: "center",
					alignItems: isSmallScreen ? "flex-start" : "center", // Centrare meglio sui piccoli schermi
					padding: isSmallScreen ? "10px" : "0", // Aggiungere padding su schermi piccoli
					boxSizing: "border-box",
					textAlign: "center",
				}}
			>
				{children}
			</Box>
		</Box>
	);
}
