import React from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../redux/store";
import { resetUtente } from "../../authentication/authenticationSlice";
import HeaderMenu from "./HeaderMenu";
import { Utente } from "../../authentication/authenticationSlice";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";

import MoreIcon from "@mui/icons-material/MoreVert";

const Header = () => {
	const utente = useSelector<RootState, Utente>(
		(state) => state.authentication.utenteLogged
	);

	const dispatch = useAppDispatch();
	const resetUtenteHendler = () => {
		dispatch(resetUtente());
	};

	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

	const handleMobileMenuOpen = (event: any) => {
		setMobileMoreAnchorEl(event.currentTarget);
	};

	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const activeStyle: React.CSSProperties = { color: "orange" };

	const mobileMenuId = "primary-search-account-menu-mobile";

	const renderMobileMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{ vertical: "top", horizontal: "right" }}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
			sx={[
				{
					"& .MuiPaper-root": {
						color: "red",
						backgroundColor: "#377199",
					},
				},
			]}
		>
			<HeaderMenu
				utente={utente}
				resetUtente={resetUtenteHendler}
				activeStyle={activeStyle}
			/>
		</Menu>
	);

	return (
		<>
			{/* visibile da sm in su */}
			<Box sx={{ display: { sm: "flex", xs: "none" } }}>
				<HeaderMenu
					utente={utente}
					resetUtente={resetUtenteHendler}
					activeStyle={activeStyle}
				/>
			</Box>
			{/* visibile da xs in giù */}
			<Box sx={{ display: { sm: "none", xs: "flex" } }}>
				<IconButton
					aria-label="show more"
					aria-haspopup="true"
					onClick={handleMobileMenuOpen}
					color="inherit"
					size="large"
					sx={{ pr: 3 }}
				>
					<MoreIcon />
				</IconButton>
			</Box>
			{renderMobileMenu}
		</>
	);
};

export default Header;
