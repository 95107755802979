import
    React from 'react';
import {Typography, Link, Box} from "@mui/material";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";

export default function Copyright() {
    return (
        <Box>
            <Typography
                variant="body2"
                color="textSecondary"
                align="center"
                sx={{mt: 4}}
            >
                {`Comunità Energetica Rinnovabile gestita da`}

                <Link
                    color="inherit"
                    href="https://www.gruppoe43.it/i43/"
                    underline="hover"
                >
                    {" "}
                    I43
                </Link>
                {"."}
            </Typography>
            <Typography
                variant="body2"
                color="textSecondary"
                align="center"
                sx={{mt: 1}}
            >
                {`${process.env.APP_NAME} - Powered by `}
                {/* {"Copyright © "} */}
                <IntegrationInstructionsIcon
                    sx={{verticalAlign: "middle", display: "inline-flex"}}
                />
                <Link
                    color="inherit"
                    href="https://www.digitalforindustry.com/"
                    underline="hover"
                >
                    {" "}
                    Digital For Industry
                </Link>{" "}
                {new Date().getFullYear()}
                {"."}
            </Typography>
        </Box>

    );
}
